<script setup lang="ts">
const { t } = useT();
const { open } = useNlcModals();
const { canPlayVault, depositsRemaining } = usePrizeVaultData();

const buttonName = computed(() => {
	if (canPlayVault.value) {
		return t("prizeVault.rewards.play");
	}
	return t("prizeVault.rewards.cash");
});

const handleClick = () => {
	dispatchGAEvent({
		event: "click_button",
		button_name: "prize_vault",
		location: "reward_cabinet"
	});
	open("LazyOModalPrizeVaultPlay");
};
</script>

<template>
	<div @click="handleClick">
		<div class="icon">
			<NuxtImg
				src="/nuxt-img/prize-vault/hiw-2.png"
				width="84"
				height="84"
				format="avif"
				loading="lazy"
				alt="prize vault"
			/>
		</div>
		<div class="content">
			<AText class="color-neutral-100" :size="16" :modifiers="['bold', 'uppercase']" as="div">
				{{ t("prizeVault.rewards.title") }}
			</AText>
			<LazyMPrizeVaultProgressBar smallSize />
			<AText class="progress-text color-neutral-60" :size="10" as="div">
				<template v-if="canPlayVault">{{ t("prizeVault.rewards.description.play") }}</template>
				<template v-else>
					<i18n-t keypath="prizeVault.rewards.description.inProgress">
						<template #key>
							{{ depositsRemaining }}
						</template>
					</i18n-t>
				</template>
			</AText>
		</div>
		<AButton variant="primary" class="btn" size="s">
			<AText variant="topeka" :modifiers="['uppercase']">{{ buttonName }}</AText>
		</AButton>
	</div>
</template>
<style lang="scss" scoped>
.progress-bar {
	margin-top: 6px;
	max-width: 172px;
}
.icon {
	display: flex;
	align-items: center;

	img {
		width: 52px;
		height: 52px;
	}
}
</style>
